<template>
  <page-base :show-btn="true" @save="save(null)" @submit="submit">
    <worker-img class="header-zjz" :worker-data="draftData" @headFileChange="headFileChange"></worker-img>
    <div class="form-block-img">
      <data-form
        :need-del="false"
        :source-data="sourceData"
        :draft-data="draftData"
        :form-parms="formParms"
        @formChange="formChange"
        :onChange="onChange"
        :wait-data="waitData"
        :is-absolut="true"
        :wait-keys="waitKeys">
      </data-form>
    </div>
  </page-base>
</template>

<script>
import PageBase from './pageBase'

import {
  getBirthByIdNo,
  getAge
} from '@/syslib/tools'

import {
  dateOperating
} from '@/fmlib'

import {
  dataAppleRequest,
  workerRequest
} from '../../../api'

import DataForm from './dataForm'
import WorkerImg from './workerImg'

export default {
  components: {
    DataForm,
    PageBase,
    WorkerImg
  },
  data () {
    return {
      formData: {},
      formCheck: true,
      isChange: false,
      workerId: null,
      data: {},
    }
  },
  activated () {
    this.workerId = this.$router.history.current.query.workerId
    this.loadData()
  },
  computed: {
    sourceData () {
      let data = this.data && this.data.sourceInfo ? this.data.sourceInfo : null
      if (data && data.birth) {
        data.age = getAge(data.birth)
      }
      return data
    },
    draftData () {
      let data = this.data ? this.data.editInfo || this.data.sourceInfo : null
      if (data && data.birth) {
        data.age = getAge(data.birth)
      }
      return data
    },
    waitData () {
      return this.data && this.data.modifyApplyInfo ? this.data.modifyApplyInfo : null
    },
    waitKeys () {
      return this.data && this.data.modifyApply && this.data.modifyApply.newData ? Object.keys(JSON.parse(this.data.modifyApply.newData)) : []
    },
    workerEduDegrees () {
      return this.$store.getters.selectDatas['worker_edu_degree']
    },
    formParms () {
      let data = [
        {
          type: 'input',
          label: '姓名',
          key: 'name'
        },
        {
          type: 'select',
          label: '性别',
          key: 'sex',
          columns: [{value: '男', label: '男'}, {value: '女', label: '女'}]
        },
        {
          type: 'select',
          label: '民族',
          key: 'clan',
          search: true,
          columns: this.$store.getters.selectDatas['worker_clan_list'].map(v => {
            return {value: v.key, label: v.label}
          })
        },
        {
          type: 'select',
          label: '政治面貌',
          key: 'politicalStatus',
          search: true,
          columns: this.$store.getters.selectDatas['worker_political_status_list'].map(v => {
            return {value: v.key, label: v.label}
          })
        },
        {
          type: 'input',
          label: '身份证号码',
          key: 'idNo'
        },
        {
          type: 'datePicker',
          label: '出生年月',
          key: 'birth',
          format: (data) => {
            return data.birth ? dateOperating.computeDay({days: 0, date: data.birth, format: 'yy-mm-dd'}) : null
          }
        },
        {
          type: 'input',
          label: '年龄',
          disabled: true,
          key: 'age'
        },
        {
          type: 'select',
          label: '个人身份',
          disabled: this.workerApplyEditType1,
          key: 'sf',
          columns: this.$store.getters.selectDatas['worker_identity_list'].map(v => {
            return {value: v.key, label: v.label}
          })
        },
        {
          type: 'select',
          label: '学历',
          key: 'education',
          columns: this.$store.getters.selectDatas['worker_education_list'].map(v => {
            return {value: v.key, label: v.label}
          })
        },
        {
          type: 'select',
          label: '学位',
          key: 'degree',
          columns: this.workerEduDegrees.map(v => ({value: v.key, label: v.label}))
        },
        {
          type: 'input',
          label: '籍贯',
          key: 'hometown'
        },
        {
          type: 'input',
          label: '联系电话',
          key: 'phone'
        },
        {
          type: 'select',
          label: '是否特殊人才',
          key: 'agreementCode',
          disabled: this.workerApplyEditType1,
          columns: [{value: '是', label: '是'}, {value: '否', label: '否'}]
        },
        {
          type: 'select',
          label: '是否港澳台及外籍人士',
          key: 'graduatedSchool',
          columns: [{value: '是', label: '是'}, {value: '否', label: '否'}]
        }
      ]
      if (!this.workerShowSelfId) {
        data = data.filter(v => v.key !== 'sf')
      }
      return data
    },
    workerApplyEditType1 () {
      return this.$store.getters.sysConfig['workerApplyEditType1']
    },
    workerShowSelfId () {
      return this.$store.getters.sysConfig['workerShowSelfId']
    }
  },
  watch: {
    draftData: {
      handler (data) {
        this.formData = data ? JSON.parse(JSON.stringify(data)) : {}
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async headFileChange (fileData) {
      let data = Object.assign({}, this.formData, {
        headFileId: fileData.id
      })
      this.formChange({
        data,
        pass: true
      })
      await this.save()
    },
    async loadData () {
      if (this.workerId) {
        let data = await workerRequest.getEdit({
          workerId: this.workerId
        })
        if (data.length !== 1) {
          this.$notice.info({
            title: '系统提示',
            desc: '数据异常，请联系管理员'
          })
        } else {
          this.data = data[0]
        }
      }
    },
    onChange (key, value) {
      let rData = {}
      if (key === 'idNo') {
        let birth = getBirthByIdNo(value)
        if (birth) {
          rData.birth = birth
          rData.age = getAge(birth)
        }
      }
      if (key === 'birth') {
        rData.age = getAge(value)
      }
      return rData
    },
    testEdited () {
      return this.isChange
    },
    async save (type) {
      if (!this.formCheck) {
        this.$notice.info({
          title: '系统提示',
          desc: '表单验证未通过'
        })
        throw new Error('表单验证未通过')
      }
      let saveData = JSON.parse(JSON.stringify(this.formData))
      if (saveData && Array.isArray(saveData.positionIds)) {
        saveData.positionIds = saveData.positionIds.join(',')
      }
      await workerRequest.saveEdit(Object.assign({}, this.formData, {
        dataId: this.formData.id,
        id: undefined
      }))
      this.isChange = false
      if (type !== 'submit') {
        this.loadData()
        this.$notice.info({
          title: '系统提示',
          desc: '保存成功'
        })
      }
      return true
    },
    async submit () {
      let data = await this.save('submit')
      if (data) {
        await dataAppleRequest.submitByWorkerId(this.workerId, {
          dataType: 'worker'
        })
        this.loadData()
        this.$notice.info({
          title: '系统提示',
          desc: '提交成功'
        })
      }
    },
    formChange (data) {
      this.isChange = true
      this.formData = data.data
      this.formCheck = data.pass
    }
  }
}
</script>

<style lang="less" scope>
.form-block-img {
  width: calc(100% - 50px);
  height: calc(100% - 160px);
  padding: 18px 25px 0;
}
.header-zjz {
  margin-top: 40px;
}
</style>