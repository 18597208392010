<template>
  <div class="header-zjz" @click="$refs.upload.click()" v-loading="loadingFile">
    <div class="zjz-y" v-if="!src">
      <div class="jia">+</div>
      <div style="margin-bottom: 9px">证件照</div>
    </div>
    <img class="zjz-y" :src="src" v-else/>
    <input type="file" style="display: none;" @change="startUpload" ref="upload">
  </div>
</template>

<script>

import {
  fileRequest
} from '@/api'

export default {
  props: {
    workerData: {
      type: Object
    }
  },
  data () {
    return {
      loadingFile: false,
      src: null,
      fileId: null
    }
  },
  watch: {
    workerData: {
      handler () {
        this.fileId = this.workerData && this.workerData.headFileId ? this.workerData.headFileId : null
      },
      immediate: true,
      deep: true
    },
    fileId: {
      handler () {
        this.loadFile()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async startUpload () {
      let files = this.$refs.upload.files
      if (files.length) {
        let file = files[0]
        if (file.type && file.type.includes('image')) {
          // let hisHeadFileId = this.fileId
          // if (hisHeadFileId) {
          //   let res = await this.$dialog.confirm('该证件照将被覆盖，是否继续操作?')
          //   if (!res) {
          //     return
          //   }
          // }
          this.loadingFile = true
          let parm = new FormData()
          parm.append('file', file)
          let fileData = await fileRequest.upload(parm)
          await this.$emit('headFileChange', fileData)
          // if (hisHeadFileId) {
          //   await fileRequest.del({'id': hisHeadFileId})
          // }
          this.$notice.success({
            title: '系统提示',
            desc: '证件照上传完成。'
          })
          this.loadingFile = false
          this.fileId = fileData.id
        } else {
          this.$notice.info({
            title: '系统提示',
            desc: '请选择图片文件。'
          })
        }
      }
    },
    async loadFile () {
      this.src = null
      if (!this.fileId) {
        return
      }
      this.loadingFile = true
      try {
        let fileData = await fileRequest.download({
          id: this.fileId
        })
        if (fileData) {
          const src = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this.src = src
        } else {
          this.src = null
        }
      } catch (error) {
        this.src = null
      }
      this.loadingFile = false
    }
  }
}
</script>

<style scoped lang="less">
.header-zjz {
  display: flex;
  justify-content: center;
  align-items: center;
  .zjz-y {
    line-height: 1;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: #E4E4E4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
    font-size: 12px;
    .jia {
      font-size: 57px;
      margin-bottom: 5px;
      font-weight: 400;
    }
  }
}
</style>
